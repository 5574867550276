import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import virtualTryOnsIcon from "../../assets/Features/virtual-try-ons.svg";
import manageInventoryIcon from "../../assets/Features/manage_inventory3.svg";
import improveCustomerExperienceIcon from "../../assets/Features/improve-customer-experience2.svg";
import growYourBusinessIcon from "../../assets/Features/grow-your-business2.svg";

// import
import FeaturesItem from "./FeaturesItem";
import "./Features.scss";

const Features = () => {
  const svgs = [
    virtualTryOnsIcon,
    manageInventoryIcon,
    growYourBusinessIcon,
    improveCustomerExperienceIcon,
  ];
  const headings = [
    "Virtual Try-Ons",
    "Manage Inventory",
    "Improve User Experience",
    "Grow Your Business",
  ];
  const texts = [
    "Enable your customers to <br /> virtually try your products.",
    "Upload all your products & easily <br /> manage them with our dashboard.",
    "Understand your customer behaviour <br /> with our analytics feature.",
    "Reduce return rates and  <br /> improve your margins.",
  ];

  const getType = (index) => {
    switch (index) {
      case 0:
        return "smallest";
      case svgs.length - 1:
        return "small";
      case svgs.length - 2:
        return "smallr";

      default:
        return "";
    }
  };
  return (
    <div className="features">
      <Container>
        <Row>
          {svgs.map((svg, index) => (
            <Col key={index}>
              <FeaturesItem
                type={getType(index)}
                svg={svg}
                heading={headings[index]}
                text={texts[index]}
              ></FeaturesItem>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default Features;
