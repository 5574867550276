import React, { Fragment } from "react";
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useLocation } from "react-router-dom";
import Parser from "html-react-parser";
import "./CustomDropdown.scss";

const CustomDropdown = ({ text, data, className: classes }) => {
  const location = useLocation();
  return (
    <Fragment>
      <UncontrolledButtonDropdown>
        <DropdownToggle
          caret
          //className=className={`${classes}__dropdown--menu`}  esa tha default,
          className={
            location.pathname === "/sapphire"
              ? "product__dropdown--buttonSapphire"
              : "product__dropdown--button"
          }
        >
          {text}
        </DropdownToggle>
        <DropdownMenu className={`${classes}__dropdown--menu`}>
          {Object.entries(data).map(([key, value]) => (
            <Fragment key={key}>
              <DropdownItem
                href={value.link}
                className={`${classes}__dropdown--heading`}
              >
                {value.heading}
              </DropdownItem>
              {/* <DropdownItem divider /> */}
              <DropdownItem
                href={value.link}
                className={`${classes}__dropdown--detail`}
              >
                {Parser(value.detail)}
              </DropdownItem>
            </Fragment>
          ))}
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    </Fragment>
  );
};

export default CustomDropdown;
