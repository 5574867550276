import React, { useEffect } from "react";
import Heading from "../Heading/Heading";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Policy.scss";

const Policy = () => {
  useEffect(() => {
    document.title = "Privacy Policy | Auggin ";
  });
  return (
    <div className="policy">
      <Heading className="policy">Privacy Policy</Heading>
      <Container>
        <Row>
          <Col>
            <div className="policy__content">
              <p>
                <strong>First Updated: </strong>10th September, 2021
              </p>
              <p>
                <strong>Last Updated: </strong> 10th September, 2021
              </p>
              <br></br>
              <p className="policy__para">
                The website <Link to="/"> www.auggin.com</Link> (“Website”) is
                operated by Auggin Technologies Private Limited, a company
                incorporated under the laws of India (hereinafter referred to as
                “<strong>our</strong>”, “<strong>us</strong>”, “
                <strong>we</strong>” or “ <strong>Auggin</strong>” which shall
                include a reference to our affiliates, associates, sister
                concerns, etc.)
              </p>
              <p className="policy__para">
                We are committed to protecting and respecting your privacy. Any
                information or data collected and processed on the Website is
                done in accordance with the provisions Information Technology
                Act, 2000 read with its Rules and other national and state laws
                relating to the collection and processing of personal data.
                Please read the following carefully to understand our practices
                regarding the collection and processing of your information and
                data.
              </p>
              <p className="policy__para">
                This Privacy Policy is required to be read in conjunction with
                the Terms of Use available at <Link to="/">www.auggin.com</Link>
                . Your access to and use of the Website is conditional upon your
                acceptance of this Privacy Policy and the Terms of Use. By
                accessing and/or using the Website, you represent that you have
                read, understood and are bound by the Terms of Use and this
                Privacy Policy, and that you consent to your information and
                data being collected, stored and used in the manner laid out in
                this Privacy Policy.
              </p>
              <h2 className="policy__head">1. Consent</h2>
              <ul>
                <li>
                  <p className="policy__para">
                    <strong>a.</strong> As stated above, by accessing and/or
                    using the Website, you consent to Your Information being
                    collected, stored, and used in the manner laid out in this
                    Privacy Policy. The collecting and retaining agency in
                    respect of Your Information is Auggin.
                  </p>
                </li>
                <li>
                  <p className="policy__para">
                    <strong>b.</strong> You hereby acknowledge that the
                    collection of Your Information as described in Clause 1 of
                    this Privacy Policy is necessary to fulfil the purposes
                    specified in Clause 2 of this Privacy Policy.
                  </p>
                </li>
                <li>
                  <p className="policy__para">
                    <strong>c.</strong> Upon request, you may review Your
                    Information as provided by you to Auggin. You may also
                    request that Your Information is corrected, completed, 
                    amended or updated. Any requests in this regard may be made
                    to the following entity by way of an email with the
                    subject-line “Privacy Policy: Request to. 
                    Review / Correct / Complete / Amend / Update Information”
                    (as.applicable): info@auggin.com
                  </p>
                </li>
                <li>
                  <p className="policy__para">
                    <strong>d.</strong> You may withdraw your consent for the
                    subsequent use of your personal data by Auggin, by sending
                    an email to the. following entity with the subject-line
                    “Privacy Policy: Withdrawal of Consent”:
                  </p>
                  <p className="policy__para">
                    info@auggin.com, clearly stating the.
                    following information:
                  </p>
                  <ul>
                    <li>
                      <p className="policy__para">
                        <strong>(i)</strong> identification details, if any;
                      </p>
                    </li>
                    <li>
                      <p className="policy__para">
                        <strong>(ii)</strong> scope of withdrawal of consent.
                        Auggin. reserves the right to seek. further details from
                        you in order to ascertain the exact nature of the
                        withdrawal of consent. Withdrawal of consent shall not
                        be treated as effective unless the communication is made
                        in the manner described above. Withdrawal of consent may
                        result in Auggin being unable to provide further
                        services to you.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <h2 className="policy__head">2. Data Collection</h2>
              <p className="policy__para">
                Auggin may collect information and data (hereinafter referred to
                as “<strong>Your Information</strong>”). Auggin shall store and
                use Your Information collected in accordance with Clause 4 of
                the Privacy Policy for the following purposes:
              </p>
              <ul>
                <li>
                  <p className="policy__para">
                    <strong>a.</strong> Providing relevant, useful and
                    appropriate information/ data/ materials to you;
                  </p>
                </li>
                <li>
                  <p className="policy__para">
                    <strong>b.</strong> Monitoring, improving and
                    troubleshooting the information/ data/ materials and
                    contents of the Website provided to you or other services
                    provided by Auggin;
                  </p>
                </li>
                <li>
                  <p className="policy__para">
                    <strong>c.</strong> Processing information and documents,
                    including financial or other sensitive personal information
                    as may be provided by you, to enable text indexing, data and
                    linkage finding, extraction of valuable insights and
                    structuring of data;
                  </p>
                </li>
                <li>
                  <p className="policy__para">
                    <strong>d.</strong> Storing of information, documents and
                    data provided by you in order to train algorithms to better
                    process information and provide relevant insights;
                  </p>
                </li>
                <li>
                  <p className="policy__para">
                    <strong>e.</strong> Operation, provision or improvement of
                    the Website for all acts incidental or ancillary to the
                    provision of the. Website and for developing and providing
                    new technologies and services.
                  </p>
                </li>
              </ul>
              <p className="policy__para">
                Auggin does not store or otherwise retain Your Information for
                longer than is necessary to achieve the purposes set out above.
                Auggin may also use cookies to collect and use Your Information
                in certain cases. This is described in detail in our Cookie
                Policy below.
              </p>
              <h2 className="policy__head">3. Cookies</h2>
              <ul>
                <li>
                  <p className="policy__para">
                    <strong>3.1.</strong> To optimize our web presence, we use
                    cookies. This allows us to improve your access to the
                    Website and also helps us to learn more about your interests
                    and provide you with essential features and services
                    including:
                  </p>
                  <ul>
                    <li>
                      <p className="policy__para">
                        <strong>a.</strong> Conducting research and diagnostics
                        to improve the content, products and services;
                      </p>
                    </li>
                    <li>
                      <p className="policy__para">
                        <strong>b.</strong> Preventing fraudulent activity;
                      </p>
                    </li>
                    <li>
                      <p className="policy__para">
                        <strong>c.</strong> Improving security.
                      </p>
                    </li>
                  </ul>
                </li>
                <li>
                  <p className="policy__para">
                    <strong>3.2.</strong> Approved third parties may also set
                    cookies when you interact with the Website.These third
                    parties include search engines, providers of analytical
                    services, social media networks and advertising companies.
                  </p>
                </li>
              </ul>
              <h2 className="policy__head">4. Security</h2>
              <p className="policy__para">
                Auggin complies with reasonable security practices and
                procedures as required by the Information Technology (Reasonable
                Security Practices and Procedures and Sensitive Personal Data or
                Information) Rules, 2011 by following industry standards such as
                International Standard IS/ISO/IEC 27001. However, since complete
                security cannot be sured by any entity, please note that Auggin
                does not guarantee the security of your information. In the
                event that Auggin becomes aware of any breach of security such
                that it affects Your Information, Auggin shall notify you using
                commercially reasonable efforts, such as through a notice on the
                Website. However, as detailed in Clause 5 of this Privacy
                Policy, you should look at this Privacy Policy regularly to
                check for such changes. Auggin claims no responsibility for the
                security of any third-party links, websites or resources that
                you may access through or in connection with the Website.
              </p>
              <h2 className="policy__head">
                5. Disclosure and Transfer of Information
              </h2>
              <ul>
                <li>
                  <p className="policy__para">
                    <strong>5.1.</strong> Auggin does not disclose or transfer
                    Your Information to any other third parties without your
                    consent, unless such disclosure or transfer is:
                  </p>
                  <ul>
                    <li>
                      <p className="policy__para">
                        <strong>5.1.1.</strong> Required in order to comply with
                        any applicable law or court order or direction; or
                      </p>
                    </li>
                    <li>
                      <p className="policy__para">
                        <strong>5.1.2.</strong> Requested by any appropriate
                        Governmental agency as per applicable law Preachily for
                        the purpose of, inter alia, verification of identity,
                        prevention, detection, investigation, prosecution or
                        punishment of any offences or incidents.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
              <h2 className="policy__head">
                6. Third Party Websites and Applications
              </h2>
              <ul>
                <li>
                  <p className="policy__para">
                    <strong>6.1.</strong> The Website may include and link to
                    features and services (such as social applications like
                    Facebook, Twitter, LinkedIn ) that are provided by a third
                    party. If you use these features and services, please
                    understand that the third parties that operate them may
                    collect information from you which will be used in
                    accordance with their own privacy policy and terms of use,
                    which may differ from ours.
                  </p>
                </li>
                <li>
                  <p className="policy__para">
                    <strong>6.2.</strong> We do not accept any responsibility or
                    liability for these third party policies or for any personal
                    data that may be collected by or through these websites or
                    services. You should always read the privacy policy of any
                    feature or service you access carefully in order to
                    understand the specific privacy and information usage
                    practices.
                  </p>
                </li>
              </ul>
              <h2 className="policy__head">7. Changes to the Privacy Policy</h2>
              <p className="policy__para">
                We retain the right to amend this Privacy Policy from time to
                time, for instance when we update the functionality of the
                Website or when there are regulatory changes. We will use
                commercially reasonable efforts to generally notify all users of
                any material changes to this Privacy Policy, such as through a
                notice on the Website. However, as a user of the Website, you
                should look at this Privacy Policy regularly to check for such
                changes. We will also update the “Last Updated” date at the top
                of this Privacy Policy, which reflects the effective date of
                this Privacy Policy. Your continued access or use of the Website
                after the date of the new Privacy Policy constitutes your
                acceptance of the new Privacy Policy. If you do not agree to the
                new Privacy Policy, you must stop accessing and/or using the
                Website. You hereby acknowledge that any collection, storage,
                processing or other use of Your Information shall be governed by
                the Privacy Policy as applicable at the time of such.
                collection, storage, processing or other use.
              </p>
              <h2 className="policy__head">8. Grievance Officer</h2>
              <p className="policy__para">
                Pursuant to Rule 5(9) of the Information Technology (Reasonable
                Security Practices and Procedures and Sensitive Personal Data or
                Information) Rules, 2011, Auggin has designated the following
                person as the Grievance Officer:
              </p>
              <p>
                <strong>Officer Name: </strong>Deepesh Alwani
              </p>
              <p>
                <strong>Email: </strong>info@auggin.com
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Policy;
