import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Parser from 'html-react-parser';

const FeaturesItem = ({ svg, heading = '', text = '', type = '' }) => {
    return (
        <div className="features__item">
            <Container>
                <Row>
                    <Col className="position-center">
                        <img src={svg} alt="" className={`features__svg ${type ? `features__svg--${type}` : ''}`} />
                    </Col>
                </Row>
                <Row>
                    <Col className="position-center">
                        <div className="features__heading">{heading}</div>
                    </Col>
                </Row>
                <Row>
                    <Col className="position-center">
                        <div className="features__text">{Parser(text)}</div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default FeaturesItem;
