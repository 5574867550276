import React from "react";
import ErrorImage from "../../assets/Landing/Error page icon.svg";
import "./ErrorPage.css";
import { Link } from "react-router-dom";
export default function ErrorPage() {
  return (
    <div className="error__supercontainer">
      <div className="error__container">
        <div className="error__textcontainer">
          <h4>Page Not Found</h4>
          <p>
            The page you were looking for was either removed or doesn't exist.
          </p>
          <Link to="/" className="error__homepagelink">
            <div className="error__redirectbuttonCont">
              <div className="error__redirectbutton">Go back Home</div>
            </div>
          </Link>
        </div>
        <div className="error__image">
          <img src={ErrorImage} alt="Error" />
        </div>
      </div>
    </div>
  );
}
