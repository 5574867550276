import { act } from "react-dom/cjs/react-dom-test-utils.production.min";
import { GET_CONTACT } from "./contact.type";

const INITIAL_STATE = {};

const contactReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CONTACT:
      return action.payload;

    default:
      return state;
  }
};

export default contactReducer;
