import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import userReducer from "./user/user.reducer";
import subscribeReducer from "./subscribe/subscribe.reducer";
import FAQReducer from "./faq/faq.reducer";
import contactReducer from "./contact/contact.reducer";

const persistConfig = {
  key: "root",
  storage,
  whitelist: [],
};
const rootReducer = combineReducers({
  user: userReducer,
  subscribe: subscribeReducer,
  faq: FAQReducer,
  contact: contactReducer,
});

export default persistReducer(persistConfig, rootReducer);
