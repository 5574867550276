import React, { useEffect } from "react";
import Heading from "../Heading/Heading";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Terms.scss";

const Terms = () => {
  useEffect(() => {
    document.title = "Terms of Use | Auggin";
  });
  return (
    <div className="terms">
      <Heading className="terms">Terms Of Use</Heading>
      <Container>
        <Row>
          <Col>
            <div className="terms__content">
              <p className="terms__para">
                These Terms and Conditions (“Terms”) outline the rules and
                regulations for the use of Auggin Technologies Private Limited's
                website, located at <Link to="/">www.auggin.com</Link>{" "}
                (“Website”) and related applications, services and products and
                any/all information, content, video(s), data, graphic(s),
                layout(s), image(s), product(s), material(s) and service(s)
                (collectively, “Auggin’s Content”) made available to you along
                with the Website.
              </p>
              <p className="terms__para">
                For purposes of these Terms and Conditions, “you”, “your” and
                “yourself” refers to you as the end user of the Website and
                “our”, “us”, “we” and “Auggin” refers to the owner of this website, Auggin
                Technologies Private Limited.
              </p>
              <p className="terms__para">
                Please read these Terms and Conditions carefully as your use of
                the Website is subject to your acceptance and strict compliance
                of these Terms.
              </p>
              <h2 className="terms__head">1. Introduction</h2>
              <p className="terms__para">
                The Terms contained herein on this webpage, shall govern your
                use of this Website, including all pages within this Website
                (collectively referred as this “Website”). These Terms apply in
                full force and effect to your use of this Website and by using
                this Website, we assume you accept these Terms and Conditions.
                Do not continue to use this Website if you do not agree with all
                of the Terms and Conditions stated on this page.
              </p>
              <h2 className="terms__head">2. Intellectual Property Rights</h2>
              <p className="terms__para">
                Other than content you own, which you may have opted to include
                on this Website, under these Terms, Auggin and/or its licensors
                own all rights to the intellectual property and material
                contained in this Website, and all such rights are reserved. You
                are granted a limited license only, subject to the restrictions
                provided in these Terms, for purposes of viewing the material
                contained on this Website.
              </p>
              <h2 className="terms__head">3. Restrictions</h2>
              <p className="terms__para">
                You are expressly and emphatically restricted from all of the
                following:
              </p>
              <ul>
                <li>
                  <p className="terms__para">
                    <strong>1.</strong> publishing any Website material in any
                    media;
                  </p>
                </li>
                <li>
                  <p className="terms__para">
                    <strong>2.</strong> selling, sublicensing and/or otherwise
                    commercializing any Website material;
                  </p>
                </li>
                <li>
                  <p className="terms__para">
                    <strong>3.</strong> publicly performing and/or showing any
                    Website material;
                  </p>
                </li>
                <li>
                  <p className="terms__para">
                    <strong>4.</strong> using this Website in any way that is,
                    or may be, damaging to this Website;
                  </p>
                </li>
                <li>
                  <p className="terms__para">
                    <strong>5.</strong> using this Website in any way that
                    impacts user access to this Website;
                  </p>
                </li>
                <li>
                  <p className="terms__para">
                    <strong>6.</strong> using this Website contrary to
                    applicable laws and regulations, or in a way that causes, or
                    may cause, harm to the Website, or to any person or business
                    entity;
                  </p>
                </li>
                <li>
                  <p className="terms__para">
                    <strong>7.</strong> engaging in any data mining, data
                    harvesting, data extracting or any other similar activity in
                    relation to this Website, or while using this Website;
                  </p>
                </li>
                <li>
                  <p className="terms__para">
                    <strong>8.</strong> using this Website to engage in any
                    advertising or marketing;
                  </p>
                </li>
              </ul>
              <p className="terms__para">
                Certain areas of this Website are restricted from access by you
                and Auggin may further restrict access by you to any areas of
                this Website, at any time, in its sole and absolute discretion.
                Any user ID and password you may have for this Website are
                confidential and you must maintain confidentiality of such
                information.
              </p>
              <h2 className="terms__head">4. Your Content</h2>
              <p className="terms__para">
                In these Website Standard Terms And Conditions, “Your Content”
                shall mean any audio, video, text, images or other material you
                choose to display on this Website. With respect to Your Content,
                by displaying it, you grant Auggin a non-exclusive, worldwide,
                irrevocable, royalty-free, sublicensable license to use,
                reproduce, adapt, publish, translate and distribute it in any
                and all media.
              </p>
              <p className="terms__para">
                Your Content must be your own and must not be infringing on any
                third party’s rights. Auggin reserves the right to remove any of
                Your Content from this Website at any time, and for any reason,
                without notice.
              </p>
              {/*<h2 className="terms__head">5. No Warranties</h2>
                        <p className="terms__para">
                            This Website is provided “as is,” with all faults, and Auggin makes no express or implied
                            representations or warranties, of any kind related to this Website or the materials
                            contained on this Website. Additionally, nothing contained on this Website shall be
                            construed as providing consultation or advice to you.
                        </p>*/}
              <h2 className="terms__head">5. Limitation of Liability</h2>
              <p className="terms__para">
                In no event shall Auggin , nor any of its officers, directors
                and employees, be liable to you for anything arising out of or
                in any way connected with your use of this Website, whether such
                liability is under contract, tort or otherwise, and Auggin ,
                including its officers, directors and employees shall not be
                liable for any indirect, consequential or special liability
                arising out of or in any way related to your use of this
                Website.
              </p>
              <h2 className="terms__head">6. Indemnification</h2>
              <p className="terms__para">
                You hereby indemnify to the fullest extent Auggin from and
                against any and all liabilities, costs, demands, causes of
                action, damages and expenses (including reasonable attorney’s
                fees) arising out of or in any way related to your breach of any
                of the provisions of these Terms.
              </p>
              <h2 className="terms__head">7. Severability</h2>
              <p className="terms__para">
                If any provision of these Terms is found to be unenforceable or
                invalid under any applicable law, such unenforceability or
                invalidity shall not render these Terms unenforceable or invalid
                as a whole, and such provisions shall be deleted without
                affecting the remaining provisions herein.
              </p>
              <h2 className="terms__head">8. Variation of Terms</h2>
              <p className="terms__para">
                Auggin is permitted to revise these Terms at any time as it sees
                fit, and by using this Website you are expected to review such
                Terms on a regular basis to ensure you understand all terms and
                conditions governing use of this Website.
              </p>
              <h2 className="terms__head">9. Assignment</h2>
              <p className="terms__para">
                Auggin shall be permitted to assign, transfer, and subcontract
                its rights and/or obligations under these Terms without any
                notification or consent required. However, you shall not be
                permitted to assign, transfer, or subcontract any of your rights
                and/or obligations under these Terms.
              </p>
              <h2 className="terms__head">10. Entire Agreement</h2>
              <p className="terms__para">
                These Terms, including any legal notices and disclaimers
                contained on this Website, constitute the entire agreement
                between Auggin and you in relation to your use of this Website,
                and supersede all prior agreements and understandings with
                respect to the same.
              </p>
              <h2 className="terms__head">
                11. Governing Law and Jurisdiction
              </h2>
              <p className="terms__para">
                This Agreement is governed by the laws of India, without regard
                to its conflicts of law provisions; and you hereby consent to
                the exclusive jurisdiction of courts in Jaipur, India with
                respect to all disputes arising out of or relating to your use
                of the Website. In addition, you hereby consent to the exclusive
                jurisdiction of any venue in such courts for any action
                commenced by you against us (or our affiliates).
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default Terms;
