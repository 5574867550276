import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { bindActionCreators } from "redux";
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useHistory } from "react-router-dom";
import { selectSubscribeEmail } from "../../redux/subscribe/subscribe.selector";
import { setSubscribeEmail } from "../../redux/subscribe/subscribe.actions";

import CustomLink from "../CustomLink/CustomLink";

import FacebookIcon from "../../assets/Footer/facebook.svg";
import TwitterIcon from "../../assets/Footer/twitter.svg";
import LinkedinIcon from "../../assets/Footer/linkedin.svg";
import YoutubeIcon from "../../assets/Footer/youtube.svg";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import "./Footer.scss";

const Footer = () => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const headings = ["Products", "Support", "Company"];
  const links = [["Sapphire"], ["Integration", "FAQ"], ["Contact Us"]];
  const tos = [
    ["/sapphire"],
    ["/integration", "/faq/general"],
    ["/contact-us"],
  ];
  
  const socialIcons = [TwitterIcon, LinkedinIcon, YoutubeIcon];
  const socialID = ["twitter", "linkedin", "youtube"];
  const socialLinks = [
    "https://twitter.com/AugginOfficial",
    "https://www.linkedin.com/company/auggin",
    "https://www.youtube.com/channel/UCZSaOz_UWEHFANRwTgdW5iw",
  ];
  const [showProducts, setShowProducts] = useState(false);
  const [showSupport, setShowSupport] = useState(false);
  const [showCompany, setShowCompany] = useState(false);
  const handleClick = (headingType) => {
    switch (headingType) {
      case "Products":
        setShowProducts(!showProducts);
        break;
      case "Support":
        setShowSupport(!showSupport);
        break;
      case "Company":
        setShowCompany(!showCompany);
        break;
    }
  };
  const getContent = () =>
    headings.map((heading, indexX) => (
      <Col key={indexX}>
        <Row className="flex-column">
          <Col>
            <div
              onClick={() => handleClick(heading)}
              className="footer__headingContainer"
            >
              <h2 className={`footer__heading footer__heading--${heading}`}>
                {heading}
              </h2>
              {(heading === "Products" && showProducts) ||
              (heading === "Company" && showCompany) ||
              (heading === "Support" && showSupport) ? (
                <MdKeyboardArrowUp
                  className={`footer__heading--arrow--${heading}`}
                  size={22}
                />
              ) : (
                <MdKeyboardArrowDown
                  className={`footer__heading--arrow--${heading}`}
                  size={22}
                />
              )}
            </div>
          </Col>
          {(heading === "Products" && showProducts) ||
          (heading === "Company" && showCompany) ||
          (heading === "Support" && showSupport)
            ? links[indexX].map((link, indexY) => (
                <Col className="footer__item" key={indexY}>
                  <CustomLink
                    color="light-grey"
                    type="redirect"
                    to={tos[indexX][indexY]}
                  >
                    {link}
                  </CustomLink>
                </Col>
              ))
            : " "}
        </Row>
      </Col>
    ));

  const getSocial = () =>
    socialLinks.map((link, index) => (
      <Col className="" key={index}>
        <a
          href={link}
          className={`footer__social--link footer__social--link-${socialID[index]} `}
        >
          <img
            className={`footer__social--svg footer__social--svg${socialID[index]}`}
            src={socialIcons[index]}
            alt=""
          />
        </a>
      </Col>
    ));


  const handleChange = (event) => setEmail(event.target.value);
  const handleClick2 = (e) => {
    e.preventDefault();
    dispatch(setSubscribeEmail(email, history));
    setEmail("");
  };

  return (
    <div className="footer">
      <Container className="footer__container">
        <div className="footer__subcontainer">
          <div className="footer__menu">
            <div>{getContent()}</div>
          </div>
          <div className="footer__formContainer">
            <form className="footer__form">
              <div className="footer__subscribe">
                <div className="footer__subscribe--inputContainer">
                  <input
                    className="footer__subscribe--input"
                    type="text"
                    placeholder="Get Latest Updates"
                    value={email}
                    onChange={handleChange}
                  />
                </div>
                <div className="footer__subscribe--buttonContainer">
                <a
                  href="/"
                  className="footer__subscribe--button"
                  onClick={handleClick2}
                >
                  Subscribe
                </a>  
                </div>
              </div>
            </form>
          </div>
          <div className="footer__rightSide">
            <div className="footer__followUs">
              <div className="footer__headingFollowUs">
                <h2 className="footer__headingFollowUs">Follow Us :</h2>
              </div>
              <div className="footer__social">{getSocial()}</div>
            </div>
          </div>
        </div>
        <div className="footer__footer">
          <div>
            <span className="footer__copyright">&copy; 2021 Auggin.</span>
          </div>
          <div className="footer__extras">
            <CustomLink
              color="light-grey"
              className="footer__extras--link"
              type="redirect"
              to="/terms-of-use"
            >
              Terms Of Use
            </CustomLink>
            <div className="footer__extras--icon">{"|"}</div>
            <CustomLink
              color="light-grey"
              className="footer__extras--link"
              type="redirect"
              to="/privacy-policy"
            >
              Privacy Policy
            </CustomLink>
          </div>
        </div>
      </Container>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  subscribeEmail: selectSubscribeEmail,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setSubscribeEmail }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
