import React, { useState,useEffect } from "react";
import Navigation from "./components/Navigation/Navigation";
import Navigation2 from "./components/Navigation/Navigation2";
import MainRouter from "./MainRouter";
import Footer from "./components/Footer/Footer";
import Footer2 from "./components/Footer/Footer2";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import "./App.scss";
import "./scss/main.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from 'axios'

function App() {
  const [ip, setIP] = useState('');
  const getData = async () => {
    const res = await axios.get('https://geolocation-db.com/json/')
    // console.log(res.data);
    setIP(res.data.IPv4)

  }

  useEffect( () => {
    
    getData()

  }, [])
  return (
    <div className="App">
      {isMobile ? <Navigation2 /> : <Navigation />}
      <MainRouter></MainRouter>
      {isMobile ? <Footer2 /> : <Footer />}
      {isMobile ? (
        <CookieConsent
          location="bottom"
          buttonText="Accept & Close"
          cookieName="CookieConsent"
          style={{
            background: "#2B373B",
            fontSize: "1.5rem",
            paddingLeft: "1rem",
            paddingRight: "1rem",
            textAlign: "center",
          }}
          buttonStyle={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#000",
            fontSize: "13px",
            fontWeight: "600",
            background: "#3ae2ed",
            margin: "1em 9.7em",
          }}
          expires={365}
        >
          We use cookies to ensure we can provide you the best experience on our
          website. You can read more about it on our{" "}
          <Link to="/" style={{ color: "#3ae2ed" }}>
            Privacy Policy
          </Link>{" "}
          page.
        </CookieConsent>
      ) : (
        <CookieConsent
          location="bottom"
          buttonText="Accept & Close"
          cookieName="CookieConsent"
          style={{
            background: "#2B373B",
            fontSize: "1.5rem",
            paddingLeft: "6rem",
            paddingRight: "6rem",
          }}
          buttonStyle={{
            color: "#000",
            fontSize: "13px",
            fontWeight: "600",
            background: "#3ae2ed",
          }}
          expires={365}
        >
          We use cookies to ensure we can provide you the best experience on our
          website. You can read more about it on our{" "}
          <Link to="/privacy-policy" style={{ color: "#3ae2ed" }}>
            Privacy Policy
          </Link>{" "}
          page.
        </CookieConsent>
      )}
    </div>
  );
}

export default App;
