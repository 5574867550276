import SubscribeActionTypes from "./subscribe.types";

// export const setSubscribeEmail = subscribeEmail => ({
//     type: SubscribeActionTypes.SET_SUBSCRIBE_EMAIL,
//     payload: subscribeEmail,
// });

import axios from "axios";

const API_URL = "https://augginbackend.azurewebsites.net/api/";
export const setSubscribeEmail = (email, history) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };
    const res = await axios.post(
      API_URL + "subscribe/",
      {
        email: email,
        category:"general",
      },
      config
    );

    // console.log("response from subscrib api", res);
    // console.log("subscriber email is ",email);
    alert("Your message has been sent. We will contact you soon.");
    // if (
    //   window.confirm("Your message has been sent. We will contact you soon.")
    // ) {
   
    // }

    dispatch({
      type: SubscribeActionTypes.SET_SUBSCRIBE_EMAIL,
      payload: {email},
    });
  } catch (error) {
    dispatch({
      type: SubscribeActionTypes.SET_SUBSCRIBE_EMAIL,
      payload:
        error.response && error.response.data.detail //err.response.data
          ? error.response.data.detail
          : error.message,
    });
  }
};
