import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import "./CustomLink.scss";

const CustomLink = ({
  type = "",
  to = "/",
  children = "Click Here",
  className: classesNames = "",
  color = "transparent",
  backgroundColor = "transparent",
  ...props
}) => (
  <Fragment>
    {props.key == "external" ? (
      <Link
        to={to}
        className={`link link--${type} link--color-${color} link--backgroundColor-${backgroundColor} ${classesNames}`}
        {...props}
      >
        {children}
      </Link>
    ) : (
      <a
        href={to}
        className={`link link--${type} link--color-${color} link--backgroundColor-${backgroundColor} ${classesNames}`}
        {...props}
        target={props.target}
      >
        {children}
      </a>
    )}
  </Fragment>
);

export default CustomLink;
