import React, { useState,useEffect } from "react";
import { Container, Accordion, Row, Col, Card } from "react-bootstrap";
import Answer from "./Answer";
import Question from "./Question";
import plusSvg from "../../assets/FAQ/plus.svg";
import minusSvg from "../../assets/FAQ/minus.svg";


import "./FAQContent.scss";

const FAQContent = ({ questions, answers,faqType,view }) => {
  const [reveal, setReveal] = useState([]);




  const changeReveal = (index) => () => {
    // console.log(reveal,"reveal");
    // console.log(index,"index");
    if (!reveal.includes(index)) {
      const newReveal = [[], index];
      setReveal(newReveal);
    } else {
      const newReveal = [...reveal];
      newReveal.splice(newReveal.indexOf(index), 1);
      setReveal(newReveal);
    }
  };
  useEffect(() => {

    // console.log(reveal)
    setReveal([])
    
    }, [faqType,view])
   

  
  // // getDerivatedStateFromProps

  return (

    
    <Container>

      <Accordion className="faq__container" defaultActiveKey="1">
        <Row className="flex-column">
          {questions.map((question, index) => (
            <Col key={index} className="faq__item">
              <Accordion.Toggle
                as={Card.Header}
                eventKey={index + 1}
                onClick={changeReveal(index + 1)}
                className="faq__header "
              >
                <div className="faq__question">
                  <Question question={question} />
                </div>

                <img
                  className="faq__svg"
                  src={reveal.includes(index + 1) ? minusSvg : plusSvg}
                  alt=""
                />
              </Accordion.Toggle>
              {
                reveal.length?
              <Accordion.Collapse  eventKey={index + 1} 
             
              >
                <Card.Body className="faq__answer "  >
                  <Answer  answer={answers[index]} />
                </Card.Body>
              </Accordion.Collapse>
              :

              <Accordion.Collapse eventKey={0} style={{transition:"none"}} >
                <Card.Body className="faq__answer "  >
                  <Answer  answer={answers[index]} />
                </Card.Body>
              </Accordion.Collapse>
          
                }

            </Col>
          ))}
        </Row>
      </Accordion>
    </Container>

       
     
  );
};

export default FAQContent;
