import React, { Fragment, useEffect } from "react";
import Landing from "../Landing/Landing";
import Features from "../Features/Features";
import Preview from "../Preview/Preview";

const Home = () => {
  useEffect(() => {
    document.title = "Auggin";
  });
  return (
    <Fragment>
      <Landing></Landing>
      <Features></Features>
      <Preview></Preview>
    </Fragment>
  );
};
export default Home;
