import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import Home from "./components/Home/Home";
import FAQ from "./components/FAQ/FAQ";
import Contact from "./components/Contact/Contact";
import Integration from "./components/Integration/Integration";
import Policy from "./components/Policy/Policy";
import Terms from "./components/Terms/Terms";
import Products from "./components/Products/Products";
import Sapphire from "./components/Products/Sapphire/Sapphire";
import MyTable from "./components/Products/Sapphire/CostTable/MyTable";
import ErrorPage from "./components/ErrorPage/ErrorPage";
const MainRouter = () => (
  <div className="main-router">
    <Switch>
      <Route exact path="/" component={Home}></Route>
      <Route exact path="/contact-us" component={Contact} />
      <Route exact path="/integration" component={Integration} />
      <Route exact path="/privacy-policy" component={Policy} />
      <Route exact path="/terms-of-use" component={Terms} />
      <Route exact path="/faq/:faqType" component={FAQ} />
      <Route exact path="/sapphire" component={Products} />
      <Route path="*" component={ErrorPage} />
    </Switch>
  </div>
);

export default MainRouter;
