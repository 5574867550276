import { createStore, applyMiddleware,compose } from 'redux';
import { persistStore } from 'redux-persist';
import thunk from "redux-thunk";

// import logger from 'redux-logger';

import createSagaMiddleware from 'redux-saga';

import rootReducer from './root-reducer';
import rootSaga from './root-saga';

// const sagaMiddleware = createSagaMiddleware();

const middleware = [thunk];

// if (process.env.NODE_ENV === 'development') {
//     middleware.push(logger);
// }

const store = createStore(rootReducer,compose( applyMiddleware(...middleware)));

// sagaMiddleware.run(rootSaga);

const persistor = persistStore(store);

export { store, persistor };
