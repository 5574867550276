import { GET_CONTACT } from "./contact.type";
import axios from "axios";
import { isMobile } from "react-device-detect";

var device="Computer";
if(isMobile){
  device="Mobile";
}

const API_URL = "https://augginbackend.azurewebsites.net/api/";
export const CreateContact = (contact, history) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };
    // console.log("Auggin Zindabaad!!");
    // console.log("contact is ", contact);
    const res = await axios.post(
      API_URL + "contact/",
      {
        first_name: contact.firstName,
        last_name: contact.lastName,
        email: contact.email,
        company_name: contact.companyName,
        phone_no: contact.phoneNumber,
        country: contact.country,
        message: contact.message,
        device_type: device
      },
      config
    );
    // console.log("contact is ", contact);
    // console.log("res is ", res);

    if (
      window.confirm("Your message has been sent. We will contact you soon.")
    ) {
      history.push("/contact-us");
    }

    dispatch({
      type: GET_CONTACT,
      payload: res,
    });
  } catch (error) {
    dispatch({
      type: GET_CONTACT,
      payload:
        error.response && error.response.data.detail //err.response.data
          ? error.response.data.detail
          : error.message,
    });
  }
};
