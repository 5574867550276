import React, { useState,useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { bindActionCreators } from "redux";
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import { useHistory } from "react-router-dom";
import { selectSubscribeEmail } from "../../redux/subscribe/subscribe.selector";
import { setSubscribeEmail } from "../../redux/subscribe/subscribe.actions";

import CustomLink from "../CustomLink/CustomLink";

import FacebookIcon from "../../assets/Footer/facebook.svg";
import TwitterIcon from "../../assets/Footer/twitter.svg";
import LinkedinIcon from "../../assets/Footer/linkedin.svg";
import YoutubeIcon from "../../assets/Footer/youtube.svg";

import "./Footer.scss";

const Footer = () => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  const headings = ["Products", "Support", "Company"];
  const links = [["Sapphire"], ["Integration", "FAQ"], ["Contact Us"]];
  const tos = [
    ["/sapphire"],
    ["/integration", "/faq/general"],
    ["/contact-us"],
  ];

  const socialIcons = [TwitterIcon, LinkedinIcon, YoutubeIcon];
  const socialID = ["twitter", "linkedin", "youtube"];
  const socialLinks = [
    "https://twitter.com/AugginOfficial",
    "https://www.linkedin.com/company/auggin",
    "https://www.youtube.com/channel/UCZSaOz_UWEHFANRwTgdW5iw",
  ];

  const getContent = () =>
    headings.map((heading, indexX) => (
      <Col key={indexX}>
        <Row className="flex-column">
          <Col>
            <h2 className="footer__heading">{heading}</h2>
          </Col>
          {links[indexX].map((link, indexY) => (
            <Col className="footer__item" key={indexY}>
              <CustomLink
                color="light-grey"
                type="redirect"
                to={tos[indexX][indexY]}
              >
                {link}
              </CustomLink>
            </Col>
          ))}
        </Row>
      </Col>
    ));

  const getSocial = () =>
    socialLinks.map((link, index) => (
      <Col className="position-center" key={index}>
        <a
          href={link}
          target="__blank"
          className={`footer__social--link footer__social--link-${socialID[index]} position-center`}
        >
          <img
            className={`footer__social--svg footer__social--svg${socialID[index]}`}
            src={socialIcons[index]}
            alt=""
          />
        </a>
      </Col>
    ));

    const onChange = (event) => setEmail(event.target.value);
  const handleClick = (e) => {
    e.preventDefault();
    dispatch(setSubscribeEmail(email, history));
    setEmail("");
  };
 

  return (
    <div className="footer">
      <Container className="footer__container d-flex flex-column">
        <Row className="flex-grow-1">
          <Col>
            <Row>{getContent()}</Row>
          </Col>
          <Col>
            <Row className="flex-column">
              <Col className="d-flex justify-content-end">
                <h2 className="footer__heading">Follow Us</h2>
              </Col>
              <Col className="d-flex justify-content-end">
                <Row className="footer__social">{getSocial()}</Row>
              </Col>
              <Col className="d-flex justify-content-end">
                <form className="footer__form">
                  <Row className="footer__subscribe">
                    <Col className="position-center">
                      <input
                        className="footer__subscribe--input"
                        type="email"
                        placeholder="Get Latest Updates"
                        value={email}
                        onChange={onChange}
                      />
                    </Col>
                    <Col className="position-center">
                      
                      
                     <a
                        href="/"
                        className="footer__subscribe--button"
                        onClick={handleClick}
                      >
                        Subscribe
                      </a> 
                    </Col>
                  </Row>
                </form>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <span className="footer__copyright">
              &copy; 2021 Auggin. All rights reserved.
            </span>
          </Col>
          <Col className="d-flex justify-content-end">
            <CustomLink
              color="light-grey"
              className="footer__extras--link"
              type="redirect"
              to="/terms-of-use"
            >
              Terms Of Use
            </CustomLink>
            <div className="footer__extras--icon">{"  |  "}</div>
            <CustomLink
              color="light-grey"
              className="footer__extras--link"
              type="redirect"
              to="/privacy-policy"
            >
              Privacy Policy
            </CustomLink>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  subscribeEmail: selectSubscribeEmail,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setSubscribeEmail }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Footer);