import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { Container, Row, Col } from "react-bootstrap";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useParams, Redirect } from "react-router-dom";
import { SelectYourPlan } from "../Products/Sapphire/SelectYourPlan";
import FAQContent from "./FAQContent";
import icon from "../../assets/FAQ/icon.svg";
import { isMobile } from "react-device-detect";
import { setFAQType } from "../../redux/faq/faq.actions";
import "./FAQOverview.scss";

const FAQOverview = ({ setFAQType, history }) => {
  const { faqType } = useParams();
  useEffect(() => {
    setFAQType(faqType);
  }, [faqType, setFAQType]);
  const [view, setview] = useState(true)

  const types = ["general", "sapphire", "subscription", "integration"];
  if (!types.includes(faqType)) return <Redirect to="general" />;

  const data = {
    general: {
      type: "general",
      heading: "General Inquiries",
      details: "New around here? Start with the basics.",
    },
    sapphire: {
      type: "sapphire",
      heading: "Sapphire Features",
      details: `Dive deeper into Sapphire's robust features.`,
    },
    subscription: {
      type: "subscription",
      heading: isMobile ? "Subscriptions" : "Subscriptions and Plans",
      details: "Learn about subscriptions, plans, payments, and more.",
    },
    integration: {
      type: "integration",
      heading: "API Integration",
      details: "Integrate API on your platform.",
    },
  };

  const questions = {
    general: [
      `What is AR and how is it helpful to me?`,
      `Do I (or my customers) require any specific hardware to use Auggin services?`,
      `Do I (or my customers) need to manually set the jewellery item on our face?`,
      `I forgot my password. What to do now?`,
      `How can I delete my account?`,
      `Is my data secure with Auggin?`,
    ],
    sapphire: [
      `How to add my inventory on Sapphire dashboard?`,
      `What is the format of the inventory image that should be uploaded?`,
      `What does  <span class="question__span">Inventory Status</span> signify in the <span class="answer__span">Upload Inventory</span> section?`,
      /*`How can I disable <em>Virtual Try-On</em> on selected inventory items?`,*/
      `How can I delete selected inventory items?`,
      `What are <span class="question__span">Custom Fields</span> (Tags)?`,
    ],
    subscription: [
      // `Can I use Sapphire for free?`,
      `How can I upgrade to the Pro version?`,
      `Are there any student discounts on the Pro version?`,
      `How can I get the enterprise version of Sapphire?`,
      `Is a credit card necessary for the subscription?`,
      `Where can I find my subscription invoice?`,
      `Where can I see my plan's usage details?`,
      `Are there any limits on no. of inventory uploaded?`,
      `Are there any limits on custom fields created?`,
      `I have exhausted my total try-ons limit. How can I get more?`,
      `What happens if I miss my payment?`,
      `How do I fix a failed payment?`,
    ],
    integration: [
      `Where can I find my API key?`,
      `How to integrate the API on my website?`,
      `Can I integrate the API on my android/iOS app?`,
    ],
  };
  const answers = {
    general: [
      `<p>Augmented Reality is the real-time rendering of digital assets such as text, image, video, audio, 3d content, etc on top of the real world. It can alter one’s perception of a real-world environment and provide new ways to experience the reality around us.</p>
            <p>It has a variety of use cases in different fields such as virtual try-ons, remote assistance and facial filters to name a few.</p>`,
      `<p>No. You don’t require any special hardware device to use our services. You can use the services
            from your regular smartphone/tablet/laptop.</p>`,
      `<p>No. Our algorithm automatically detects the user’s face and places the jewellery item at the right
            place.</p>`,
      `<p>In case you have forgotten your password, you can click on <span class="answer__span">Forgot Password</span> on your login screen and follow the steps.</p>`,
      `<p>If you wish to delete your account, please write to us at info@auggin.com.</p>`,
      `<p>Yes, we at Auggin take your privacy very seriously and ensure best practices for data safety.
            For more information on data security, please read our <a href="/privacy-policy">Privacy Policy</a>.</p>`,
    ],
    sapphire: [
      `<p>To add inventory on the Sapphire dashboard, follow these steps: </p>
            <ul>
            <li> a. Go to <a href="https://app.auggin.com/" target="__blank">Sapphire dashboard</a> and login to your account. </li>
            <li> b. Click on <span class="answer__span">Upload</span> Inventory on the left panel. </li>
            <li> c. Select files from your computer by clicking on <span class="answer__span">Browse</span> button. </li>
            <li> d. Fill all the item details by clicking on the <span class="answer__span">Edit</span> button in the Approval table. </li>
            <li> e. Once you fill all the details, the item status changes to Success and is ready for your approval. </li>
            
            f. Select the item and click on the <span class="answer__span">Approve</span> button to add the inventory.
            </ul>`,
      `<p>The image should follow the below mentioned format. Other formats may affect the virtual try-on
            experience of such items.</p>
            <ul>
            <li>a. Image size shoukd be less than 2Mb</li>
            <li>b. Image file type should be .png file format</li>
            <li>c. Image background should have a single solid color, preferably white</li>
            </ul>
        `,
      `<p>Inventory Status lets you know if the uploaded item is ready to be added on the dashboard. You are
            required to take the following actions for different status:</p>
            <ul>
            <li>a. <strong><em>Pending Status</em></strong> - The item is not ready for approval. If you see this status, check if you have
            filled all the item details. If the details are completed, wait for a few seconds to change the
            status to <em>Success</em>.</li>
            <li>b. <strong><em>Success Status</em></strong> - The item is ready for approval. If you see this status, you can simply select
            the item and approve it.</li>
            </ul>`,

      /*`<p>To disable virtual try-on, follow these steps:</p>
            <ul>
            <li>a. Go to <a href="/">Sapphire dashboard</a> and login to your account.</li>
            <li>b. Click on <span class="answer__span">Manage Inventory</span> on the left panel.</li>
            <li>c. Select the items and click on the <span class="answer__span">Disable</span> button to disable them.</li>
            </ul>`,*/
      `<p>To delete items from dashboard, follow these steps:</p>
            <ul>
            <li>a. Go to <a href="https://app.auggin.com/" target="__blank">Sapphire dashboard</a> and login to your account.</li>
            <li>b. Click on <span class="answer__span">Manage Inventory</span> on the left panel.</li>
            <li>c. Select the items and click on the <span class="answer__span">Delete</span> button to delete them.</li>
            </ul>`,
      `<p>Custom Fields are defined by you and are helpful for the better management of your inventory. These are the keywords which help to describe an item. You can create multiple tags
      and assign multiple tags at once to any particular item.</p>
            
            <p>To create these fields, follow these steps:</p>
            <ul>
            <li>a. Go to <a href="https://app.auggin.com/" target="__blank">Sapphire dashboard</a> and login to your account.</li>
            <li>b. Click on <span class="answer__span">Add Custom Field</span>  on the left panel.</li>
            <li>c. Click on <span class="answer__span">Add</span> button, fill all the details and save the new field.</li>
            </ul>`,
    ],
    subscription: [
      // `<p>Yes, you can use Sapphire for free by subscribing to the Basic version. The Basic version is free for
      //       lifetime and comes with a limited set of features which can be seen <a href="/sapphire#select-your-plan">here</a>.</p>`,
      `<p>To upgrade to Pro version of Sapphire, follow these steps:</p>
            <ul>
            <li>a. Go to <a href="https://app.auggin.com/" target="__blank">Sapphire dashboard</a> and login to your account.</li>
            <li>b. Click on <span class="answer__span"><img src=${icon} height="17px" width="20px" alt="err">→ Subscription Details</span>  on the top right corner.</li>
            <li>c. Click on <span class="answer__span">Upgrade to Pro</span> button on the main panel and make the necessary payment.</li>
            </ul>`,
      `<p>No, there aren’t any student discounts on the Pro version. You can try using the Basic version of
            Sapphire.</p>`,
      `<p>If you are an enterprise with more feature requirements, contact us <a href="/contact-us">here.</a></p>`,
      `<p>For the Basic version, there is no requirement of credit card. For the Pro version, you are required
            to have a credit card.</p>`,
      `<p>Your subscription invoice is emailed to you on your registered email id at the time of payment.</p>`,
      `<p>To see your plan’s usage details, follow these steps:</p>
            <ul>
            <li>a. Go to <a href="https://app.auggin.com/" target = "__blank"
            ">Sapphire dashboard</a> and login to your account.</li>
            <li>b. Click on <span class="answer__span"><img src=${icon} height="17px" width="20px" alt="err"> → Subscription Details</span>  on the top right corner.</li>
            <li>c. The details can now be seen on the main panel.</li>
            </ul>`,
      `<p>There is an inventory limit of 50 items for the Basic version and 1000 items for the pro version</p>`,
      `<p>This feature is not available in the Basic version. For the Pro version, there is no limit on the
            custom fields.</p>`,
      `<p>If you are a Basic version user, upgrade to Pro version to get more no. of try-ons. If you are a Pro
            version user, re-subscribe to the pack to get more no. of try-ons.</p>`,
      `<p>We send you timely notifications for your next payment. Still in case you miss the payment,
            your account is deactivated and can be reactivated by completing the payment.</p>`,
      `<p>If your payment fails, wait for a few hours to either let the transaction complete by bank or
            refund your amount. If the problem persists, write to us at info@auggin.com.</p>`,
    ],
    integration: [
      `<p>To get your API key, follow these steps:</p>
            <ul>
            <li>a. Go to <a href="https://app.auggin.com/" target="__blank">Sapphire dashboard</a> and login to your account.</li>
            <li>b. Click on <span class="answer__span"><img src=${icon} height="17px" width="20px" alt="err"> → Subscription Details</span> on the top right corner.</li>
            <li>c. The API key can now be seen on the <span class="answer__span">Main Panel</span>.</li>
            </ul>`,
      `<p>To integrate the API on your website, please refer to the <a href="/integration">Integration Documents</a>.</p>`,
      `<p>Currently, we are available only on the web. We will soon be releasing the API for other platforms. To
            get the updates, please subscribe to our newsletter.</p>`,
    ],
  };




  const onClick = (type) => () => {
    history.push(type);
   
  };

  const getSections = () =>
    Object.entries(data).map(([key, value]) => {

      const selected = value.type === faqType ? "faq__sections--selected" : "";
      return (
        <Col
          key={key}
          id={value.type}
          className={`faq__sections--container ${selected}`}
          onClick={onClick(value.type)}
        >
          <div className="faq__sections--heading">{value.heading}</div>
          <div className="faq__sections--details">{value.details}</div>
        </Col>
      );
    });
  const getSectionsMobile = () => {
    return (
      <div className="faq__sections--supercontainer">
        {Object.entries(data).map(([key, value]) => {
          const selected =
            value.type === faqType ? "faq__sections--selected" : "";
          return (
            <div
              key={key}
              id={value.type}
              className={`faq__sections--container ${selected}`}
              onClick={onClick(value.type)}
             
            >
              <div  className="faq__sections--heading">{value.heading}</div>
            </div>
          );
        })}
        ;
      </div>
    );
  };
  return (
    <div className="faq__overview">
      <Container>
        <Row>
          <Col md={{ offset: 1, span: 3 }} className="faq__sections">
            <div className="flex-column">
              {isMobile ? getSectionsMobile() : getSections()}
            </div>
          </Col>
          <Col md={8}>
            
            <FAQContent
              questions={questions[faqType]}
              answers={answers[faqType]}
              faqType={faqType}
            
            >

            </FAQContent>
              <div className="more_ques">Didn't find what you were looking for? Contact us <a style={{color:"#3ae2ed", textDecoration:"none"}} href="https://www.auggin.com/contact-us" >here</a>.</div>
          </Col>
        </Row>
      
      </Container>
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setFAQType }, dispatch);

export default connect(null, mapDispatchToProps)(withRouter(FAQOverview));
