import React from "react";
import ReactCompareImage from "react-compare-image";

import CustomLink from "../CustomLink/CustomLink";
import { Container, Row, Col } from "react-bootstrap";

import leftImage from "../../assets/Landing/female.png";
import rightImage from "../../assets/Landing/female-aug.png";

import "./Landing.scss";

const Landing = () => (
  <div className="landing">
    <Container className="landing__container position-center">
      <Row className="flex-grow-1">
        <Col md={5} className="position-center flex-column align-items-start">
          <div>
            <Row>
              <Col>
                <h1 className="landing__heading">
                  Power your business <br /> with{" "}
                  <span className="landing__span">AR</span>
                </h1>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="landing__text">
                  Auggin helps you improve customer experience and grow your
                  business using augmented reality.
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="landing__sft">
                <div className="landing__link">
                  <a href="https://www.auggin.com/contact-us" >
                    Start Free Trial
                  </a>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col>
          <div className="landing__compare">
            <ReactCompareImage
              leftImage={leftImage}
              rightImage={rightImage}
              sliderPositionPercentage={0.485}
            />
          </div>
        </Col>
      </Row>
    </Container>
  </div>
);

export default Landing;
