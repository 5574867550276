import React, { useEffect } from "react";
import Heading from "../Heading/Heading";
import FAQOverview from "./FAQOverview";
import "./FAQ.scss";

const FAQ = () => {
  useEffect(() => {
    document.title = "Frequently Asked Questions | Auggin";
  });
  return (
    <div className="faq">
      <Heading className="faq"> Frequently Asked Questions </Heading>
      <FAQOverview></FAQOverview>
   
    </div>

  );
};

export default FAQ;
