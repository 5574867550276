import React, { Fragment, useEffect } from "react";
import { useParams } from "react-router-dom";
import Sapphire from "./Sapphire/Sapphire";
import TryItYourself from "./Sapphire/TryItYourself";
// import SelectYourPlan from "./Sapphire/SelectYourPlan";
import LoveIt from "./Sapphire/LoveIt";

import "./Products.scss";

const Products = () => {
  const { productType } = useParams();
  useEffect(() => {
    document.title = "Sapphire Virtual Try-on | Auggin ";
  });

  return (
    <Fragment>
      <Sapphire />
      <div id="try-it-yourself"  >
       <p style={{height:"1px"}}></p>
      <TryItYourself />
      </div>
      {/* <SelectYourPlan /> */}
      <LoveIt />
    </Fragment>
  );
};

export default Products;
