import React, {useEffect} from "react";
import { Container } from "react-bootstrap";
import InfiniteCarousel from "react-leaf-carousel";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./Sapphire.scss";
import { isMobile } from "react-device-detect";
import analyticsImage from "../../../assets/Sapphire/Analytics.svg";
import arviewImage from "../../../assets/Sapphire/AR_View.svg";
import realtimeImage from "../../../assets/Sapphire/real_time.svg";
import sappmobImage from "../../../assets/Sapphire/preview.svg";
import AOS from 'aos';
import "aos/dist/aos.css";
// import myVideo from "https://gdurl.com/gqTv";


// myVideo="https://gdurl.com/gqTv";
const Sapphire = () => {


  useEffect(() => {
    AOS.init({
      duration : 100,
      once: false,
      mirror: true,
      anchorPlacement:"bottom-bottom"
     
    
    });
  }, []);



  return (
   
    <div className="sapphire">
      <div className="sapphire__container position-center">
        <div className="sapphire__leftSide">
          <h1 className="sapphire__heading">Sapphire</h1>
          <div className="sapphire__text">
            Power of offline store,{isMobile ? <br /> : " "}right on your
            website.
          </div>
          <div className="sapphire__info">
         
          </div>
        </div> 
        
        <div className="sapphire__img">
          {isMobile ? 
            <div className="sapphire-preview__img">
              <img src={sappmobImage} alt="Sapphire Preview Image" />
            </div>  
            : 
            /* <video autoPlay muted width="700"> */
              /* <source src={myVideo} type="video/mp4" /> */
              /* <source src=myVideo type="video/mp4" /> */
            /* </video> */
            <div className="sapphire-preview__img">
              <img src={sappmobImage} alt="Sapphire Preview Image" />
            </div> 
          }
        </div>
      </div>

     <div className="sapphire__vertical">
     {/* <div className="myCarousel">
              {isMobile ? (
                <Carousel
                  showIndicators={true}
                  showArrows={false}
                  autoPlay={true}
                  interval={5000}
                  infiniteLoop={true}
                  centerMode={false}
                  autoPlay={true}
                  showThumbs={true}
                  showStatus={false}
                  width={"115%"}
                >
                  <div className="sapphire-transisting-heading-divison">
                    <h2 className="sapphire-transisting-heading">
                      Sapphire is an augmented reality based software solution
                      for online jewelry brands.
                    </h2>
                  </div>
                  <div className="sapphire-transisting-heading-divison">
                    <h2 className="sapphire-transisting-heading">
                      {" "}
                      Our real time virtual try-on technology
                      {isMobile ? <br /> : ""} bring your jewelry images to
                      life. And our algorithm automatically adjusts
                      {isMobile ? <br /> : ""}them on the customer's face, so
                      they {isMobile ? <br /> : ""}just drool over your designs.
                    </h2>
                  </div>
                  <div className="sapphire-transisting-heading-divison">
                    <h2 className="sapphire-transisting-heading">
                      {" "}
                      Also get the analytics to understand{" "}
                      {isMobile ? <br /> : ""}your customer's behaviour while
                      you easily manage all this on our dashboard.
                    </h2>
                  </div>
                </Carousel>
              ) : (
                <InfiniteCarousel
                  breakpoints={[
                    {
                      breakpoint: 500,
                      settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                      },
                    },
                    {
                      breakpoint: 768,
                      settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                      },
                    },
                  ]}
                  dots={false}
                  arrows={false}
                  sidesOpacity={0}
                  sideSize={0}
                  slidesToScroll={1}
                  slidesToShow={1}
                  scrollOnDevice={true}
                  autoCycle={false}
                  cycleInterval={5000}
                  pauseOnHover={true}
                >
                  <div className="sapphire-transisting-heading-divison">
                    <h2 className="sapphire-transisting-heading">
                      Sapphire {isMobile ? <span>&nbsp;</span> : " "}is{" "}
                      {isMobile ? <span>&nbsp;</span> : " "}an{" "}
                      {isMobile ? <span>&nbsp;</span> : " "}augmented
                      {isMobile ? <br /> : " "}reality
                      {isMobile ? " " : <br />}based software solution{" "}
                      {isMobile ? <br /> : " "} for online{" "}
                      {isMobile ? " " : <br />}
                      jewellry brands.
                    </h2>
                  </div>
                  <div className="sapphire-transisting-heading-divison">
                    <h2 className="sapphire-transisting-heading">
                      Our real time virtual try-on technology bring your jewelry
                      images to life. And our algorithm automatically adjusts
                      them on the customer's face, so they just drool over your
                      designs.
                    </h2>
                  </div>
                  <div className="sapphire-transisting-heading-divison">
                    <h2 className="sapphire-transisting-heading">
                      Also get the analytics to understand your customer's
                      behaviour while {isMobile ? " " : <br />} you easily
                      manage all this on our dashboard.
                    </h2>
                  </div>
                </InfiniteCarousel>
              )}
            </div> */}
        


{/* <div class="content_container">

<h1>Content Container</h1>

<p>Cillum laborum reprehenderit dolor tempor ullamco veniam in veniam mollit ex minim id anim dolore cillum.</p>

<ul class="slide_navigation">
    <li><a href="#slide_1" class="active">Slide 1</a></li>
    <li><a href="#slide_2">Slide 2</a></li>
    <li><a href="#slide_3">Slide 3</a></li>
</ul>

</div> */}

    {/* <div className="all_vdiv">    */}
        

  <div className="vdiv" >
          <div className="vimg" data-aos="fade-up" data-aos-duration="500">
          <img src={arviewImage} alt="Media"  />
          </div>
          <div className="vtext" data-aos="fade-up" data-aos-duration="500" >
          {isMobile ? 
            <div >
             <p> An augmented reality based </p>  
              <p>software solution for online  </p>   <p> jewellery brands.</p>
            </div>
            : 
            <div>
              An augmented reality based software 
              <p style={{marginTop:"2.6rem"}}>solution for online jewellery brands.</p>
            </div>
          }
          </div>
        </div>


  <div className="vdiv" id="vdiv2" >
          <div className="vimg"  data-aos="fade-up" data-aos-duration="500" >
          <img src={realtimeImage} alt="Media" marginLeft="auto" marginRight="auto"/>
          </div>
          <div className="vtext"  data-aos="fade-up" data-aos-duration="500" >

          {isMobile ? 
            <div>
             <p> Real-time virtual try-ons with </p>
              <p>auto adjustment feature that </p> 
            <p>  provide a mirror like experience.</p>
            </div>
            : 
            <div>
              Real-time virtual try-ons with auto adjustment 
             <p style={{marginTop:"2.6rem"}}>feature that provide a mirror like experience.</p>
            </div>
          }
          </div>
        </div>


    
  <div className="vdiv"  >
          <div className="vimg"  data-aos="fade-up" data-aos-duration="500" >
          <img src={analyticsImage} alt="Media" />
          </div>
          <div className="vtext"  data-aos="fade-up" data-aos-duration="500" >
            
            {isMobile ? 
            <div>
                 <p>  Real-time insights on your </p>
               <p> customer behaviour  </p>
              <p> with an easy to use dashboard.</p>

            </div>
            : 
            <div>
              Real-time insights on your customer 
             behaviour<p style={{marginTop:"2.6rem"}}> with an easy to use dashboard.</p>

            </div>
          }
            
          </div>
        </div>  

  </div>  

  
{/* </div> */}


{/* 
<div class="slider  "  >

<div class="slides"  >


<div class="slide  tag" id="header"  >
    <div class="inner_content">
    <div className="vdiv ">
          <div className="vimg">
          <img src={arviewImage} alt="Media" />
          </div>
       
          <div className="vtext">
          {isMobile ? 
            <div >
              An augmented reality based  
              <p>software solution for online<br /> jewellery brands.</p>
            </div>
            : 
            <div>
              An augmented reality based software 
              <p style={{marginTop:"4rem"}}>solution for online jewellery brands.</p>
            </div>
          }
          </div>
         
        </div>
  </div>
  <div>
    </div>
</div>


<div class="slide tag"  >
    <div class="inner_content">
    <div className="vdiv">
          <div className="vimg">
          <img src={realtimeImage} alt="Media" marginLeft="auto" marginRight="auto"/>
          </div>
        
          <div className="vtext">

          {isMobile ? 
            <div>
              Real-time virtual try-ons with 
              <p>auto adjustment feature that <br/>
              provide a mirror like experience.</p>
            </div>
            : 
            <div>
              Real-time virtual try-ons with auto adjustment 
             <p style={{marginTop:"4rem"}}>feature that provide a mirror like experience.</p>
            </div>
          }
          </div>
          
        </div>
  </div>
  <div>
    </div>
</div>



<div class="slide tag"   >
    <div class="inner_content">
    <div className="vdiv">
          <div className="vimg">
          <img src={analyticsImage} alt="Media" />
          </div>
       
          <div className="vtext">
            
            {isMobile ? 
            <div>
              Real-time insights on your
              <br/> customer behaviour with
              <p>  an easy to use dashboard.</p>
            </div>
            : 
            <div>
              Real-time insights on your customer 
             behaviour<p style={{marginTop:"4rem"}}> with an easy to use dashboard.</p>

            </div>
          }
            
          </div>
        
        </div>  
    </div>
</div>

 

</div>
        </div>  */}











    </div>
  );


  
};

export default Sapphire;
