import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import augginLogoText from "../../assets/Home/auggin-logo-text.svg";
import { CgMenuLeftAlt } from "react-icons/cg";
import { VscClose } from "react-icons/vsc";
import CustomLink from "../CustomLink/CustomLink";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

import "./Navigation.scss";

const Navigation2 = () => {
  const location = useLocation();
  const [sidebar, setSidebar] = useState(false);
  const showsidebar = () => {
    setSidebar(!sidebar);
  };
  const [productDropdown, setProductDropdown] = useState(false);
  const showProductDropdown = () => {
    setProductDropdown(!productDropdown);
  };
  const data = {
    sapphire: {
      id: "sapphire",
      heading: "Sapphire",
      detail: "Virtual tryons for <br /> online jewellery brands",
      link: "/sapphire",
    },
  };

  let navigationClass =
    window.location.pathname == "/sapphire"
      ? "navigation navBack"
      : "navigation";

  return (
    <div
      className={
        location.pathname === "/sapphire" ? "navigation navBack" : "navigation"
      }
    >
      <div className="navigation__menuIcon">
        <CgMenuLeftAlt
          onClick={showsidebar}
          size={33}
          color={location.pathname === "/sapphire" ? "#fff" : "#707070"}
        />
      </div>
      <div md={2} className="navigation__container">
        <Link className="navigation__container--link" to="/">
          <img
            src={augginLogoText}
            alt="AugginLogo"
            className="navigation__text"
            size={20}
          />
        </Link>
      </div>
      <nav className={sidebar ? "navigation__menu active" : "navigation__menu"}>
        <ul className="navigation__menuItems">
          <li className="navigation__headerItem">
            <div className="navigation__headerItem--closeicon">
              <VscClose color="#707070" size={27} onClick={showsidebar} />
            </div>
            <CustomLink to="/" >
              <div className="navigation__headerItem--logo">
                <img src={augginLogoText} alt="AugginLogo" />
              </div>
            </CustomLink>
          </li>
          <li
            className="navigation__item navigation__item--first"
            onClick={showsidebar}
          >
            <CustomLink
              color={"dark-grey"}
              to="https://app.auggin.com/"
              type="empty"
              className="navigation__button"
            >
              LOGIN
            </CustomLink>
          </li>
          <li className="navigation__item">
            <div className="navigation__item--products__container">
              <div
                className="navigation__item--products"
                onClick={showProductDropdown}
              >
                <div className="navigation__item--products__heading">
                  Products
                </div>
                <div className="navigation__item--products__arrow">
                  {productDropdown ? (
                    <MdKeyboardArrowUp size={22} />
                  ) : (
                    <MdKeyboardArrowDown size={22} />
                  )}
                </div>
              </div>
              {productDropdown ? (
                <div className="navigation__item--products__list">
                  <div
                    className="navigation__item--products__list--sapphire"
                    onClick={showsidebar}
                  >
                    <div className="navigation__item--products__list--sapphire__heading">
                      <CustomLink color="dark-grey" type="link" to="/sapphire">
                        <strong>
                          <em>Sapphire</em>
                        </strong>
                        <div className="navigation__item--products__list--sapphire__message">
                      <em>
                        Virtual tryons for
                        <br /> jewellery brands
                      </em>
                    </div>
                      </CustomLink>
                    </div>

                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </li>
          <li className="navigation__item" onClick={showsidebar}>
            <CustomLink color="dark-grey" type="link" to="/integration">
              Integration
            </CustomLink>
          </li>
          <li className="navigation__item" onClick={showsidebar}>
            <CustomLink color="dark-grey" type="link" to="/contact-us">
              Contact Us
            </CustomLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Navigation2;
