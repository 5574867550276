import SubscribeActionTypes from './subscribe.types';

const INITIAL_STATE = {
    subscribeEmail: '',
};

const subscribeReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SubscribeActionTypes.SET_SUBSCRIBE_EMAIL:
            return {
                ...state,
                subscribeEmail: action.payload,
            };

        default:
            return state;
    }
};

export default subscribeReducer;
