import FAQActionTypes from './faq.types';

const INITIAL_STATE = {
    faqType: 'general',
};

const FAQReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FAQActionTypes.SET_FAQ_TYPE:
            return {
                ...state,
                faqType: action.payload,
            };

        default:
            return state;
    }
};

export default FAQReducer;
