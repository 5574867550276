import React, { useEffect } from "react";
import Heading from "../Heading/Heading";
import "./Integration.scss";

const Integration = () => {
  useEffect(() => {
    document.title = "Integration Documents | Auggin";
  });
  return (
    <div className="integration">
      <Heading className="integration">Integration</Heading>
      <div className="integration__supercontainer">
        <div className="integration__container">
          <p>
            Releasing soon, Stay Tuned.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Integration;
