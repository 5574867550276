import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import { HashRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate as Persist } from 'redux-persist/integration/react';
import { store, persistor } from './redux/store';

import ScrollToTop from './components/Utils/ScrollToTop';
import reportWebVitals from './reportWebVitals';

import './index.css';

import ReactGA from 'react-ga';

ReactGA.initialize('G-M6PFJY9HZ6');
ReactGA.pageview('/');

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <Router>
                <Persist persistor={persistor}>
                    <ScrollToTop />
                    <App />
                </Persist>
            </Router>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

reportWebVitals();
