import React from "react";
import Heading from "../../Heading/Heading";
import { isMobile } from "react-device-detect";
import "./TryItYourself.scss";
import laptopImage from "../../../assets/Sapphire/laptop.svg";
import mobileImage from "../../../assets/Sapphire/mobile.svg";



const TryItYourself = () => (

  <div className="tiy" id="try">
    
    <Heading className="tiy" >Try It Yourself</Heading>
    
     <div className="tiy__centerdiv">
      
      {isMobile ? <img src={mobileImage} alt="Mobile"  /> : <img src={laptopImage} alt="Laptop"  />}
      
     </div>

     <div className="tiy__centerdiv">    
      {isMobile ? <div style={{display:"flex",flexDirection:"column"}}> <div className="tiy__tryontext">Experience the virtual try-on</div>  <div className="tiy__tryontext2" >by yourself</div></div>:  <div className="tiy__tryontext">Experience the virtual try-on by yourself</div>}
      
    </div>
    <a href="https://sapphire.auggin.com/#tryon" style={{textDecoration:"none"}}>
     <div className="tiy__centerdiv" >

        <div className="trylink" >
            Try Now 
        </div>
    
    </div>
    </a>
  </div>
  
  // onClick={e=> window.open("https://sapphire.auggin.com/#tryon","_self")}
);

export default TryItYourself;
