import React from "react";
import { Link, useLocation } from "react-router-dom";
import augginLogo from "../../assets/Home/auggin-logo.svg";
import augginText from "../../assets/Home/auggin-text.svg";
import { CgMenuLeftAlt } from "react-icons/cg";
import CustomLink from "../CustomLink/CustomLink";
import CustomDropdown from "../CustomDropdown/CustomDropdown";
import { Container, Row, Col } from "react-bootstrap";

import "./Navigation.scss";

const Navigation = () => {
  const location = useLocation();

  const data = {
    sapphire: {
      id: "sapphire",
      heading: "Sapphire",
      detail: "Virtual tryons for <br /> jewellery brands",
      link: "/sapphire",
    },
  };

  let navigationClass;
  navigationClass =
    window.location.pathname == "/sapphire"
      ? "navigation navBack"
      : "navigation";

  return (
    <div
      className={
        location.pathname === "/sapphire" ? "navigation navBack" : "navigation"
      }
    >
      <Container>
        <Row>
          <Col md={2} className="position-center navigation__container">
            <img src={augginLogo} alt="" className="navigation__logo" />
            <Link to="/">
              <img src={augginText} alt="" className="navigation__text" />
            </Link>
          </Col>
          <Col className="navigation__list">
            <Row>
              <Col className="navigation__item position-center">
                <CustomDropdown
                  className="product"
                  text="Products"
                  data={data}
                />
              </Col>
              <Col className="navigation__item position-center">
                <CustomLink
                  color={
                    location.pathname === "/sapphire" ? "white" : "dark-grey"
                  }
                  type="link"
                  to="/integration"
                >
                  Integration
                </CustomLink>
              </Col>

              <Col className="navigation__item position-center">
                <CustomLink
                  color={
                    location.pathname === "/sapphire" ? "white" : "dark-grey"
                  }
                  type="link"
                  to="/contact-us"
                >
                  Contact Us
                </CustomLink>
              </Col>
            </Row>
          </Col>

          <Col md={2} className="position-center">
            <CustomLink
              color={location.pathname === "/sapphire" ? "white" : "dark-grey"}
              to="https://app.auggin.com/"
              key="external"
              target="__blank"
              type="empty"
              className="navigation__button"
            >
              LOGIN
            </CustomLink>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Navigation;
