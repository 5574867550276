import React from 'react';
import SapphirePreview from './Sapphire/SapphirePreview';

import './Preview.scss';
const Preview = () => (
    <div className="preview" >
        <SapphirePreview />
    </div>
);

export default Preview;
