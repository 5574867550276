import React from "react";
import Heading from "../../Heading/Heading";
import { Container, Row, Col } from "react-bootstrap";
import CustomLink from "../../CustomLink/CustomLink";
import "./LoveIt.scss";
import { isMobile } from "react-device-detect";

import { selectSubscribeEmail } from "../../../redux/subscribe/subscribe.selector";
import { setSubscribeEmail } from "../../../redux/subscribe/subscribe.actions";

const onChange = (event) => setSubscribeEmail(event.target.value);

const LoveIt = ({ subscribeEmail, setSubscribeEmail }) => (
    <div className="lit">
      {/* <Heading className="lit">Let's Begin</Heading> */}
      
      <div className="lit__container">
        
        <div className="lit__uppertext">
           {isMobile ? 
               < div style={{textAlign:"left",marginLeft:"2.5rem"}}>
          
               <div style={{fontSize:"6rem",fontWeight:"600"}}> <div style={{color:"#707070",margin:"5rem 0"}}>realtime. </div>  <div  style={{color:"#3ae2ed",margin:"5rem 0"}} className="lit_mirror" >mirrorlike. </div></div>
               {/* <div style={{fontSize:"6rem",fontWeight:"600"}}></div> */}
              
               <div  style={{fontSize:"6rem",fontWeight:"600",padding:"0"}}> <div style={{color:"darkgrey",margin:"5rem 0"}} >anytime. </div> <div style={{color:"#cccbcb",margin:"5rem 0"}} > anywhere. </div></div>
   
               <div style={{fontSize:"1.8rem",marginTop:"3.3rem"}}>Don't just show, make them experience your jewellery.</div>
   
            </div>
           :
           <div className="lit_txt" >
          
            <div className="lit_txt_head" style={{fontSize:"9rem",fontWeight:"600"}}> <span style={{color:"#707070"}}>realtime. </span>  <span  style={{color:"#3ae2ed"}} className="lit_mirror" >mirrorlike. </span></div>
            {/* <div style={{fontSize:"6rem",fontWeight:"600"}}></div> */}
           
            <div className="lit_txt_head" style={{fontSize:"9rem",fontWeight:"600",padding:"0"}}> <span style={{color:"darkgrey"}} >anytime. </span> <span style={{color:"#cccbcb"}} > anywhere. </span></div>

            <div style={{fontSize:"3rem",marginTop:"2.3rem"}}>Don't just show, make them experience your jewellery.</div>

         </div>
           }
        </div>

        <div className="lit__link">
          <a href="https://www.auggin.com/contact-us" >
           Get in Touch
          </a>
        </div>

        {/* <div className="lit__ortext">
            or
        </div> */}

       </div>


        {/* <div className="lit__subbox">
            <div className="lit__bottomtext">
                Join the waitlist to get notified of the software release.
            </div>

            <Container className=" lit__subscribe" >

                    <Col className="d-flex justify-content-center">
                        <form className="footer__form">
                        <Row className="lit__subscribe">
                            <Col className="position-center lit__subsribe" style={{color: "black"}}>
                            <input
                                className="lit__subscribe--input  lit__subscribe"
                                type="email"
                                placeholder="Enter your email"
                                value={subscribeEmail}
                                onChange={onChange}
                                style={{color: "black"}}
                            />
                            </Col>
                            <Col className="position-center lit__subscribe" style={{color: "black"}}>
                            <a
                                href="/subscribe"
                                className="lit__subscribe--button"
                                onChange={onChange}
                                style={{color: "black"}}
                            >
                                Notify Me
                            </a>
                            </Col>
                        </Row>
                        </form>
                    </Col>


            </Container>
        </div> */}

      
    
    </div>
  );
  
  export default LoveIt;