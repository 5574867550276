import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import CustomLink from "../../CustomLink/CustomLink";
import sapphireImage from "../../../assets/Sapphire/preview.svg";
import sapphireImage2 from "../../../assets/Sapphire/Sapphire Graphic Mobile.svg";
import "./SapphirePreview.scss";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

const SapphirePreview = () => (
  <div className="sapphire-preview" style={{position:"relative", zIndex:"1"}}>
    <div className="sapphire-preview-subdiv" style={{position:"relative", zIndex:"2"}}>
      <div className="sapphire-preview__container position-center" style={{position:"relative", zIndex:"3"}}>
        <div className="flex-grow-1" style={{position:"relative", zIndex:"4"}}>
          <div className="sapphire-preview__headingContainer">
            <h1 className="sapphire-preview__heading">Sapphire</h1>
          </div>
          <div className="sapphire-preview__textContainer">
            <p className="sapphire-preview__text">
              Power of offline store,{isMobile ? <br /> : ""} right on your
              website.
            </p>
          </div>
          <div>
            <div className="sapphire-preview__lm"style={{position:"relative", zIndex:"10"}}>
              <CustomLink
                to="/sapphire"
                color="primary"
                type="link"
                className="sapphire-preview__link"
              >
                Learn More {" > "}
              </CustomLink>
          
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="sapphire-preview__img">
      <img src={sapphireImage} alt="Media" />

      {/* 
            <video autoPlay loop muted height="500" width="600">
               <source src={sapphireVideo} type="video/mp4" />
            </video> 
            */}
    </div>
    
  </div>
);

export default SapphirePreview;
